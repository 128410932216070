import "./sidebar-page.scss";
import "./sidebar-metadata.scss";
import "./sidebar-menu.scss";

import * as dompack from "dompack";

// If there is a submenu in the sidebar,
// add a button to jump to this submenu for mobile
dompack.onDomReady(checkForSectionMenu);


function checkForSectionMenu()
{
  let sectionmenu = document.querySelector(".ttresource-sidebar__menu");
  if (!sectionmenu)
    return;

  let bottombarcontent = document.querySelector(".pageheader-bottombar__content");

  // Jump to section-menu button
  let menuwrapper =
      <div class="pageheader-bottombar__menuwrapper">
        <a class="pageheader-bottombar__menutoggle"
           href="#section-navigation"
           aria-label="Go to section navigation"
           >
          <span class="fa fa-stream"></span>
        </a>
      </div>;

  /*
  Open section-menu as dropdown

  import "@mod-tue/webdesigns/shared/components/spc-toggler";

  let menuwrapper =
      <div class="pageheader-bottombar__menuwrapper">
        <button class="pageheader-bottombar__menutoggle"
                aria-expanded="false"
                aria-label="Toon menu voor ..."
                type="button"
                aria-haspopup=""
                >
          <span class="fa fa-stream"></span>
        </button>
      </div>;

  // Create a copy for the pulldown version
  let pulldownmenu = <div class="pageheader-bottombar__sectionmenu"></div>;
  pulldownmenu.appendChild(sectionmenu.cloneNode(true));
  menuwrapper.appendChild(pulldownmenu);
  */

  bottombarcontent.appendChild(menuwrapper);
}
