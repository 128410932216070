import * as dompack from "dompack";
//import * as videotools from "../components/video";
//import SwipeDetect from "../components/swipedetect";

/*

*/

export default class SlideShow
{
  constructor(nodes, options)
  {
    this.nodes = [];
    this.players = [];
    this.dotNodes = [];
    this.curSlide = -1;

    this.options = Object.assign({ timeout: 5000
                                 , dotNodes: null
                                 , pauseNode: null
                                 , enableswipe: true
                                 }, options);

    // Make a list of nodes
    for (let node of nodes)
    {
      node.addEventListener("transitionend", this.onSlide.bind(this));
      if (this.nodes.length)
      {
        // Set z-index of slides not currently visible to 0
        node.classList.add("slideshow--slidein");
        node.style.zIndex = 0; // Not visible
      }
      else
      {
        this.containerNode = node.parentNode;
        this.containerNode.style.zIndex = 0; // Reset z-index
        node.style.zIndex = 2; // Currently visible
      }
      this.nodes.push(node);
      // Set visibility to visible (slides are hidden initially)
      node.style.display = "block";
    }

    // No need to slide
    if (!this.nodes.length)
      return;
    if (this.nodes.length == 1)
    {
      // There is one slide, maybe it contains a video?
      let node = this.nodes[0];
      if (node.hasAttribute("data-video"))
        this.initializeVideoSlide(node, 0, false);
      return;
    }
/*
    if(this.containerNode && this.options.enableswipe)
    {
      new SwipeDetect(this.containerNode);
      this.containerNode.addEventListener('wh:swipe', function(ev)
        {
          if(ev.detail.direction == 'w')
          {
            let nextSlide = this.curSlide + 1;
            this.nextNode(nextSlide >= this.nodes.length ? 0 : nextSlide, false);
          }
          else if(ev.detail.direction == 'e')
          {
            let nextSlide = this.curSlide - 1;
            this.nextNode(nextSlide < 0 ? this.nodes.length - 1 : nextSlide, true);
          }
        }.bind(this));
    }
*/
    // Pause on hover, if requested
    if (this.options.pauseNode)
    {
      this.options.pauseNode.addEventListener("mouseover", this.pause.bind(this));
      this.options.pauseNode.addEventListener("mouseout", this.play.bind(this));
    }

    // Make a list of dots
    if (this.options.dotNodes)
      Array.from(this.options.dotNodes).forEach((dot, idx) =>
      {
        this.dotNodes.push(dot);
        dot.addEventListener("click", event =>
        {
          this.nextNode(idx);
          // If the slideshow was stopped, resume it
          this.resume();
        });
      });

    // Show the first slide
    this.nextNode();
  }

  nextNode(nextindex, moveleft)
  {
    // Show the next slide after 'timeout' ms
    clearTimeout(this.nextTimeout);
    if (!this.paused)
      this.nextTimeout = setTimeout(this.nextNode.bind(this), this.options.timeout);

    // Run the actual animation within an animation frame, so the animation only continues if the browser is actually showing
    // the animation
    if (this.slideTimeout)
      cancelAnimationFrame(this.slideTimeout);
    this.slideTimeout = requestAnimationFrame(() =>
    {
      if(typeof nextindex != 'undefined')
      {
        if(nextindex == this.curSlide)
          return;

        this.nextSlide = nextindex;

        if(this.prevNode && this.prevNode.classList.contains("slideshow--slideout"))
        {
          this.prevNode.style.zIndex = 0; // Not visible
          this.prevNode.classList.remove("slideshow--slideout");
          this.prevNode.classList.add("slideshow--slidein");
        }
      }

      // Keep a reference to the previous slide
      this.prevNode = this.curSlide < 0 ? null : this.nodes[this.curSlide];

      // Select the next slide
      this.containerNode.classList.remove("slideshow--slideleft");
      if(typeof this.nextSlide != 'undefined' && this.nextSlide != null)
      {
        if(this.nextSlide < this.curSlide && typeof moveleft != 'boolean' || moveleft)
          this.containerNode.classList.add("slideshow--slideleft");

        this.curSlide = this.nextSlide;
        this.nextSlide = null;
      }
      else
        ++this.curSlide;

      if (this.curSlide >= this.nodes.length)
        this.curSlide = 0;
      let curNode = this.nodes[this.curSlide];

      if (curNode.hasAttribute("data-video"))
      {
        curNode.addEventListener("click", event => this.initializeVideoSlide(curNode, this.curSlide, true));
      }

      this.slideTimeout = null;

      // If there is a previous slide, insert the new slide and start the transition
      if (this.prevNode)
      {
        // Update z-index of current and next slide
        this.prevNode.style.zIndex = 1; // About to slide out
        curNode.style.zIndex = 2; // About to slide in
        // Start the transition
        this.prevNode.classList.add("slideshow--slideout");
        curNode.classList.remove("slideshow--slidein");
      }

      // Update the active slider dot
      this.dotNodes.forEach((dot, idx) =>
      {
        // Cannot use classList.toggle with second parameter as it's not supported by IE11
        if (idx == this.curSlide)
          dot.classList.add("slideshow--activedot");
        else
          dot.classList.remove("slideshow--activedot");
      });

      dompack.dispatchCustomEvent(window, "ws:nextslide", { bubbles: true
                                                          , cancelable: false
                                                          , detail: { target: this
                                                                    , idx: this.curSlide
                                                                    }
                                                          });
    });
  }

  initializeVideoSlide(node, idx, startplay)
  {
/*
    if( !window.utwenteCookies.getConsent() )
      return;

    if (!videotools.isVideoNodeInitialized(node))
    {
      videotools.initializeVideoNode(node).then(player =>
      {
        this.players[idx] = player;

        // Hide poster image and play button
        node.classList.remove("widget__photoslide--poster");

        // Stop the slideshow when the video starts playing
        node.addEventListener("video:play", event => this.stop());
        // Show the slider dots when the video is paused (they're hidden again
        // when the video resumes which triggers a video:play event)
        node.addEventListener("video:pause", event => this.toggleDotNodes(true));
        // If the video has finished playing, resume the slideshow
        node.addEventListener("video:finish", event => this.resume());

        // If the player is ready, start it
        if (startplay)
          node.addEventListener("video:ready", event => player.play());
      });
    }
    else if (this.players[idx])
    {
      // Hide poster image and play button
      node.classList.remove("widget__photoslide--poster");

      // Resume/replay the video
      this.players[idx].play();
    }
*/
  }

  // Pause the slideshow on hover
  pause()
  {
    this.paused = true;
    if (this.nextTimeout)
    {
      clearTimeout(this.nextTimeout);
      this.nextTimeout = null;
      this.dotNodes.forEach((dot, idx) =>
      {
        if (idx == this.curSlide)
          dot.classList.add("slideshow--paused");
      });
    }
  }

  // Play the slideshow again after mouseout, unless it's stopped
  play()
  {
    if (this.stopped)
      return;

    this.paused = false;
    if (!this.nextTimeout)
    {
      this.nextTimeout = setTimeout(this.nextNode.bind(this), this.options.timeout / 2);
    }
    this.dotNodes.forEach(dot => dot.classList.remove("slideshow--paused"));
  }

  // Stop the slideshow until it's resumed
  stop()
  {
    this.pause();
    this.stopped = true;
    this.toggleDotNodes(false);
  }

  // Start playing the slideshow again
  resume()
  {
    this.stopped = false;
    this.toggleDotNodes(true);
    for (let node of this.nodes)
      node.classList.add("widget__photoslide--poster");
    this.play();
  }

  toggleDotNodes(show)
  {
    this.dotNodes.forEach(dot => dot.style.display = show ? "" : "none");
  }

  onSlide(event)
  {
    // Check if this is the slide that is no longer active
    if (event.target == event.currentTarget
        && event.target.classList.contains("slideshow--slideout"))
    {
      // Reset z-index and classes
      event.target.style.zIndex = 0; // Not visible
      event.target.classList.remove("slideshow--slideout");
      event.target.classList.add("slideshow--slidein");
    }
  }
}
