import * as whintegration from "@mod-system/js/wh/integration";
import * as dompack from 'dompack';
import { getTid, getHTMLTid } from "@mod-tollium/js/gettid";
import JSONRPC from "@mod-system/js/net/jsonrpc";

//import "./feedbackbar.scss";
//import "./tags.scss";
//import "./teaserbar.scss";

//import * as multiselect from "../form_multiselect/index.es";

import "@mod-tue/webdesigns/site/site.lang.json";


/*

ADDME:
- "rpc" -> "rpc-html" and "rpc-template" ?

!! breaking changes
..

V2 (4TU<TUE<inGenious ?)

V2.1 (ACOI)
- event delegation for filteredoverview toggle buttons
- callback for when expanding or collapsing filters
- public method setFilters()
- public method resetFilters()
- reset form event also handled
- FIXME: using .header-menubar to measure amount of content at the top which is fixed/sticky to determine where to scroll to
  (change this... to either have an callback which can give this height OR ensure scroll-margin-top is used on the resultscontainer)

- teaserbar using (more) CSS variables
- tags using CSS variables
- radio's are also picked up as tag

- added the options.tags_remap callback
- FIXME: removing a tag now correct set's either the value from this.options.defaultfilters OR the first item

V2.2 (ACOI)
- 12 dec: better documentation for method "rpc"
- 12 dec: options.onafterrefresh
- 12 dec: isFiltersActive() from minfin_wig but ALSO using defaultfilters to check if a field is active
- 14 dec: setFilters() and prefillFormWithFilterValues() will now also set fields which have "" as value
- 14 dec: added options.ongotrpcresults - currently only used if method is "rpc"
- 14 dec: added options.debug_tags
- 21 dec: ontagremove
- 16 jan 2023: fixed veld uit hiddenfields in url

V2.3 (TUE BOOST!)
- improved ypos detection for determining whether to show the teaserbar
- improved scroll to results code

FIXME: maybe add the hiddenfields at the last minute to filters passed to the filtering functions?


Usage:

- tracking
  To enable tracking make sure import "@mod-publisher/js/analytics/gtm"; is loaded

- use a data-filtertagtitle="" to specify the title to use in the taglist tag.
  (for example if you don't want the counter shown in a label in the tag or if you want to abbreviate the title in the tag)

Options

Type
This will determine who is responsible to update/filter what items are shown.

type:
- "clientside"
  Filtering is done clientside or partially.
  Partially means that "fields_rpc" is used. Fieldnames added here will be send to the RPC
  and the RPC must return the id's of all items which match using the specified fields.

  Each item must have an data-filterdata with the metadata as JSON.

- "rpc"
  Filtering is done by sending all filters to the specified RPC+function.
  The RPC function must provide:
  - a field "resultshtml" which contains the new HTML to place in the node specified in options.node_results
  - a field "totalcount" which contains the total amount of results (including all pages if paginated)


  - rpc
  - rpcsearchfunction
  - getfiltersforrpc  - (optional) callback, it it passed the filters and must return the filters to use for the RPC
  - node_results      - Node in which to store HTML returned by the RPC searchfunction

  For a module RPC use: { rpc: new JSONRPC({ url: "/wh_services/modulename/rpc_name/" }) }
  For page RPC use:     { rpc: new JSONRPC(); }

- "serverside"

  Means a normal form submit is used. (so the page will essentially reload, which we seldom actually want)

- "custom"

  A callback will used which should update the items.

  - options.onfilterchange




TODO:
- verify correct GA event usage
- check if filtermatchcallbacks works correctly
- merge this.filters and this.filters_titles into this.filters = [{ value: "", title: "" }] ?

*/

window.__filteredoverviews = [];


/** @short Basic filtered overview support for pages which already handle stuff themselves.

    - Header toggle
    - API for expanding/collapsing the header filters
    - API to set 'X results' teaser for mobile screens


.filteredoverview__form
.filteredoverview__toggleaction
html.filteredoverview-showfilters

*/
export class FilteredOverviewHeader
{
  constructor(formnode, options)
  {
    window.__filteredoverview = this;
    window.__filteredoverviews.push(this);

    if (!options)
      options = {};

    this.options = options;
    // console.log("[filteredoverview] this.options", this.options);

    this.class_showfilters = "filteredoverview--showfilters";

    // let formnode = document.querySelector(".filteredoverview__form");
    if (!formnode)
    {
      console.error(".filteredoverview__form NOT FOUND");
      return;
    }

    if (formnode.initialized)
    {
      console.error("[filteredoverview] Already initialized on this form node!");
      return;
    }

    formnode.initialized = true;
    this.form = formnode;

    document.body.addEventListener("click", evt => this.doCheckforFilterToggle(evt));

    /*
    for( let node of document.body.querySelectorAll(".filteredoverview__toggleaction") )
    {
      // NOTE: We use mouseup to fire before (and be able to prevent) focus
      //       This way we can prevent the focus ring on mouse events, but keep it for keyboard navigation
      node.addEventListener("mousedown", evt => { evt.preventDefault(); }); // prevent getting focus
      node.addEventListener("mouseup",   evt => { this.toggleFiltersExpanded() });
      node.addEventListener("keydown",   evt => this.toggleFiltersExpandedIfEnter(evt) );
    }
    */
  }

  doCheckforFilterToggle(evt)
  {
    // console.log(evt.target);

    let closebutton = evt.target.closest(".filteredoverview__toggleaction");
    if (!closebutton)
      return;

    this.toggleFiltersExpanded();
  }

  onFilterChange(evt)
  {
    this.refreshResults(true);
  }

  onSubmit(evt)
  {
    evt.preventDefault();
    this.refreshResults(true);
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Public misc

  scrollResultsIntoView()
  {
    // FIXME: we need to know the size of the header when it's shrunken due to having scrolled
    //        add a callback to request the expected header size when scrolling?
    //        or detect that a scroll margin has been set through CSS and just use element.scrollIntoView ?

    //let stickyheaderbottom = document.querySelector(".header-projectpage").getBoundingClientRect().bottom;
    let stickyheaderbottom = document.querySelector(".header-menubar").getBoundingClientRect().bottom;
    // let resultstop = this.options.resultsanchor.getBoundingClientRect().top;
    let resultstop = this.getResultsTop();

    // let scrollY = resultstop + document.scrollingElement.scrollTop - stickyheaderbottom;
    let scrollY = resultstop - stickyheaderbottom;
    window.scrollTo({ top: scrollY, left: 0, behavior: "smooth" });
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Filterbar expanding/collapsing


  /// Set whether the filters are expanded or collapsed
  setFiltersExpanded(expand)
  {
    document.documentElement.classList.toggle(this.class_showfilters, expand);

    if (!expand && window.bLazy) // Page content becoming visible again
      window.bLazy.revalidate();

    let fieldnodes = this.form.querySelectorAll("input[name], select[name]");

    // NOTE: using visibility: hidden; (?) on the panel also removed it from the tab navigation list
    //       However we would like this to work automatically without needing to remember those kind of workarounds.
    for(let idx = 0; idx < fieldnodes.length; idx++)
    {
      if (expand)
        fieldnodes[idx].removeAttribute("tabindex");
      else
        fieldnodes[idx].setAttribute("tabindex", "-1"); // disable ability to focus
    }

    this.checkNeedToShowTeaserBar();

    if (expand && this.options.onexpandfilters)
      this.options.onexpandfilters();
    else if (!expand && this.options.oncollapsefilters)
      this.options.oncollapsefilters();
  }

  clickedOnFilterBar()
  {
    this.setFiltersExpanded(false);
    this.scrollResultsIntoView();
  }

  toggleFiltersExpandedIfEnter(evt)
  {
    if (evt.key == "Enter")
      this.toggleFiltersExpanded();
  }

  toggleFiltersExpanded()
  {
    let expand = !document.documentElement.classList.contains(this.class_showfilters);
    this.setFiltersExpanded(expand);

    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  isFilterBarExpanded()
  {
    return document.documentElement.classList.contains(this.class_showfilters);
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Teaser bar

  setTeaserResultCount(totalfound, totalshown)
  {
    let teasetext = "";

    if (this.options.getfloatingteasertext)
      teasetext = this.options.getfloatingteasertext(totalfound, totalshown);
    else
      console.error("No getfloatingteasertext");

    this.setHoveringFeedbackText(teasetext);
  }


  setHoveringFeedbackText(text)
  {
    //console.info("!!! setHoveringFeedbackText", text, this.resultscountnode);

    if (!this.resultscountnode)
      this.__createResultsCountNode();

    this.resultscountnode.textContent = text;
  }

  __createResultsCountNode()
  {
    /*
    let container = document.createElement("a");
    container.className = "filteredoverview__teaser";
    container.href = "#results";
    */

    // We need a dialog to be at the toplayer, over the filters modal dialog.
    let container = document.createElement("dialog");
    container.className = "filteredoverview__teaser";

    let button = document.createElement("button");
    button.className = "filteredoverview__teaser__button";
    // container.addEventListener("click", evt => this.setFiltersExpanded(false));
    button.addEventListener("click", evt => this.clickedOnFilterBar());
    container.appendChild(button);

    let label = document.createElement("div");
    label.className = "filteredoverview__teaser__content filteredoverview__teaser__label";
    button.appendChild(label);
/*
    let label = document.createElement("div");
    label.className = "filteredoverview__teaser__label";
    contentnode.appendChild();
*/
    /*
    label.tabIndex = 0;
    label.addEventListener("keydown", evt => this.toggleFiltersExpandedIfEnter(evt) );
    contentnode.appendChild(label);

    let closebtn = document.createElement("div");
    closebtn.className = "filteredoverview__teaser__close";
    closebtn.textContent = "Close";
    contentnode.appendChild(closebtn);
    */

    document.body.appendChild(container);

    this.node_teaserbar = container;
    this.resultscountnode = label;

    window.addEventListener("resize", evt => this.checkNeedToShowTeaserBar(evt));
    document.addEventListener("scroll", evt => this.checkNeedToShowTeaserBar(evt));

    this.checkNeedToShowTeaserBar();
  }


  getResultsTop()
  {
    let body_top =  document.body.getBoundingClientRect().top;

    /*
    Find the highest place on the page where we can find any info on the results.
    (either the anchor, the results themselves or the feedback bar)

    Whe'll check the y position of:
    - this.options.resultsanchor
    - this.resultsnode
    - filteredoverview__feedbackandviewbar
    */
    let results_ypos = -1;
    if (this.options.resultsanchor)
    {
      let rect = this.options.resultsanchor.getBoundingClientRect();
      // console.info("Resultsanchor", rect.top, rect);
      if (rect.height > 0)
        results_ypos = rect.top - body_top;
    }

    if (this.feedbacknode)
    {
      let rect = this.feedbacknode.getBoundingClientRect();
      // console.info("feedback", rect.top, rect);
      if (rect.height > 0 && rect.top < results_ypos)
        results_ypos = rect.top - body_top;
    }

    if (this.resultsnode)
    {
      let rect = this.resultsnode.getBoundingClientRect();
      // console.info("results", rect.top, rect);
      if (rect.height > 0 && rect.top < results_ypos)
        results_ypos = rect.top - body_top;
    }

    return results_ypos;
  }

  checkNeedToShowTeaserBar()
  {
    if (!this.node_teaserbar) // || !this.options.resultsanchor)
      return;

    // let viewportheight = document.body.getBoundingClientRect().height;
    let viewportheight = document.documentElement.clientHeight;
    let results_ypos = this.getResultsTop();

    let results_not_visible =    results_ypos == -1 // element not visible or reflows at all
                              || results_ypos > viewportheight // need to scroll down to see it
                              || document.querySelector("dialog[open]") !== null;

    //console.log({ results_not_visible: results_not_visible });

    // The feedback bar also tells the amount of results AND it means whe're almost at the results.
    // So if we reach the top of the feedback bar we can hide the teaser bar.
    this.node_teaserbar.classList.toggle("filteredoverview__teaser--aboveresults", results_not_visible);

    // FIXME: if a modal dialog is open, move us to that dialog ??

    if (this.node_teaserbar.show) // support for <dialog> ?
      this.node_teaserbar.show();
    else
      this.node_teaserbar.setAttribute("open", "");
  }
}
//


/** @short FilteredOverview

    - The class you make MUST use doFilter() or doFilterForce() to trigger a refresh
    - The class you make may offer a showResults() which uses this.filters to change the content on the page

*/
export default class FilteredOverview extends FilteredOverviewHeader
{
  constructor(formnode, options)
  {
    options = { trackingid:             "" // name to identify this for tracking (or debug) purposes - will be send to the dataLayer for tracking

              , additionalform:         null
              , resultsanchor:          null
              , node_results:           null // used for RPC to place new results into + element to jump to if options.resultsanchor isn't set

              /** Initial values for the form fields.
                  These will be set if the URL didn't contain a field/value which counts as "active".
                  (ignored fields defined in options.fields_dontcountasactive and fields
                   which value matches the one in options.defaultfilters)
                */
              , defaultfilters:         {}

              /** Fixed filter values which aren't meant for the user to change.
                  These can be used to pass state. For example:
                  - Pass the id of the site so it'll show items for the correct test or live site
                  - Pass the language of the page/site so the RPC will only return English articles
                  - Pass which sets of tags the RPC needs to render

                  These fields will
                  - NOT appear in the URL
                  - NOT appear as a tag in the feedback bar
              */
              , hiddenfilters:          {}

              , method:                 "clientside"

              // Tags
              , tags_remap:             null

              // Fields used for method "clientside"
              , fields_hidetag:           []   // fields which must not appear as a tag
              , fields_notforfiltering:   []   // fields which must not be used for filtering (but do appear on the URL) -- FIXME: also remove these before sending to RPC
              , fields_dontcountasactive: []   // fields which does not count towards an "active filter" (such as sorting options, pagination, invisible fields)
              , fields_rpc:               []   // type:"clientside" - fields which require the usage of the RPC
              , fields_textmatch:         []   // type:"clientside" - do partial textmatch for these fields
              , filtermatchcallbacks:     {}   // (FIXME: untested) map with a function per filter field which given the filter value and item data will return true if it's a match
              , finalize_itemmatch:       null // final callback used in isMatch() to remove more matches
              , sortorderings:            {}

              // Fields used for method "rpc"
              , rpc:                    null
              , rpcsearchfunction:      ""
              , getfiltersforrpc:       null

              // Fields used for all methods //method "custom"
              , onfilterchange:         null // in "custom" use it for refreshing/filters.. in other to influence filters (instance.filters)
              , ongotrpcresults:        null // after the RPC responded and before refreshing using these results this callback will be used with the results as parameter
              , onafterrefresh:         null
              , onremovetag:            null // code to handle before the removal of the tag is done.. if it returns true, the filteredoverview doesn't need to remove the value itself

              , getfloatingteasertext:  null

              , debug:                  false
              , debug_rpc:              false
              , debug_ordering:         false
              , debug_tags:             false

              , ...options
              };
    super(formnode, options);

    console.log("[filteredoverview] options", options);

    this.debug = location.href.indexOf("debugfilteredoverview") > -1;
    this.debug_search = location.href.indexOf("debugfilteredoverview") > -1;
    this.filters = {};
    this.filters_titles = {};

    // fields to trach what filters have been reported using the window.dataLayer (to Google Tagmanager)
    this.filter_lastseenkeys = [];
    this.filter_lastsettings = {};

    this.items = [];
    this.visibleitems = [];

    if (!this.options.resultsanchor)
    {
      this.options.resultsanchor = document.body.querySelector('[id="results"]');

      if (!this.options.resultsanchor)
      {
        // NOTE: rather not use <a id="" /> because accessibility checkers will complain the link doesn't have a href
        console.error(`[filteredoverview] Must add an <div id="results"> to scroll to when using the teaser bar`);
      }
    }


    if (window.dataLayer)
    {
      if(!this.options.trackingid)
        console.error("Please set the trackingid for this page (for tracking)");
      else
        dataLayer.push({ filterpage: this.options.trackingid });
    }


    if (!this.areOptionsValid())
      return;

    if (this.options.method == "clientside")
    {
      for (let item of this.options.items)
      {
        let itemdata = item.dataset.filterdata ? JSON.parse(item.dataset.filterdata) : null;
        this.items.push({ node:       item
                        , filterdata: itemdata
                        });
      }

      if (this.options.debug)
        console.log("FilteredOverview] Gather all itemdata:", this.items);
    }


    this.feedbacknode = document.body.querySelector(".filteredoverview__feedback");
    this.resultsnode = this.options.node_results ?? document.body.querySelector(".filteredoverview__results");

    if (this.debug)
    {
      this.form.classList.add("filteredoverview--debugmode");

      console.log({ fbn: this.feedbacknode
                  , rsn: this.resultsnode
                  });
    }



    // Check for removing tags on pages which show selected filters as tags
    let filtertagscontainer = document.querySelector(".filtertags__items");
    if (filtertagscontainer)
      filtertagscontainer.addEventListener("click", evt => this.doCheckForTagRemoval(evt));


    // Make a list of filter fields
    this.filternodes = this.form.querySelectorAll("input[name], select[name]");
    if (this.options.additionalform)
    {
      console.info("additionalform", this.options.additionalform);

      if (Array.isArray(this.options.additionalform))
      {
        // Add all input and select controls from each components container (usually a form, but doesn't need to be)
        for (let container of this.options.additionalform)
        {
          console.info("Adding form components contained in", container);

          let additionalfields = container.querySelectorAll("input[name], select[name]");
          this.filternodes = [ ...this.filternodes, ...additionalfields ];
        }
      }
      else
      {
    console.log("XXX");
        let additionalfields = this.options.additionalform.querySelectorAll("input[name], select[name]");
        this.filternodes = [ ...this.filternodes, ...additionalfields ];
      }
    }

    // if (this.debug)
      console.info("[filteredoverview] field nodes: ", this.filternodes);


    this.oldfilterstr = ""; //JSON.stringify(this.filters);
    this.filters = this.getFiltersFromURL();
    // console.info("Filters from URL", this.filters);

    // If we didn't get any filters through the URL use defaultfilters if available
    if (!this.isFilterActive() && this.options.defaultfilters)
    {
      console.info("[filteredoverview] setting filters to options.defaultfilters");
      this.filters = this.options.defaultfilters;
    }

    this.setFiltersWithoutRefresh(this.filters);

    this.initChangeEvents();
  }

  areOptionsValid()
  {
    if (this.options.method == "rpc")
    {
      if (!this.options.rpc || !this.options.rpcsearchfunction)
      {
        console.info('Option "rpc" and "rpcsearchfunction" must be specified when using the method "rpc".');
        return false;
      }
    }
    else if (this.options.method == "serverside")
    {
      // validate ?
    }
    else if (this.options.method == "clientside")
    {
      // We require an options.items with the array of nodes we must filter
      if (!("items" in this.options))
      {
        console.info('Option "items" must be specified with the nodes to filter when using method "clientside".');
        return false;
      }
    }
    else if (this.options.method == "custom")
    {
      if (!this.options.onfilterchange)
      {
        console.info('Option "onfilterchange" must be specified when using method "custom"');
        return;
      }
    }
    else
    {
      console.error('Please specificy method: "serverside", "rpc", "clientside" or "custom" for FilteredOverview');
      return;
    }

    return true;
  }


  setFilters(filters)
  {
    this.setFiltersWithoutRefresh(filters);
    this.refreshResults(false); // no user-interaction refresh (for tracking)
  }

  setFiltersWithoutRefresh(filters)
  {
    console.info("[FilteredOverview] setFilters", filters);

    this.prefillFormWithFilterValues(filters);
    this.updateURL(filters);

    if (window.multiselect) // FIXME
      multiselect.refreshAll();
  }




  initChangeEvents()
  {
    console.info("[filteredoverview] initChangeEvents");

    // submit (by enter, 'go' on virtual keyboard or click on a submit button) must force a refilter
    this.form.addEventListener("submit", evt => { this.onSubmit(evt); });
    this.form.addEventListener("reset", evt => this.doClearFilters(evt));



    if (this.options.additionalform)
    {
      if (Array.isArray(this.options.additionalform))
      {
        // Add all input and select controls from each components container (usually a form, but doesn't need to be)
        for (let container of this.options.additionalform)
        {
          if (container.tagName == "FORM")
            container.additionalform.addEventListener("submit", evt => { this.onSubmit(evt); });
          else
            console.warn("not a form", container, container.tagName);
        }
      }
      else
        this.options.additionalform.addEventListener("submit", evt => { this.onSubmit(evt); });
    }

    for( let node of this.filternodes)
      node.addEventListener("change", ev => this.onFilterChange(ev));
  }


  getFiltersFromURL()
  {
    let filters = {};

    //get url params if set
    let urlparamsdone = [];
    for( let node of this.filternodes)
    {
      let val = urlparamsdone.indexOf(node.name) == -1 ? this.getUrlParam(node.name) : "";

      if(val != "")
      {
        let inptype = node.nodeName == "INPUT" ? node.getAttribute("type") : "";
        if( inptype == "checkbox")
        {
          let vals = val.split(",");

          filters[ node.name ] = vals;
        }
        else if(inptype == "radio")
          filters[ node.name ] = val;
        else if(node.nodeName == "SELECT")
          filters[ node.name ] = val;
        else
          filters[ node.name ] = val;

        urlparamsdone.push( node.name );
      }
    }

    filters = { ...filters, ...this.options.hiddenfilters };

    return filters;
  }

  /** @short update the filters in the DOM (this doesn't trigger updating the internal filter values)
   */
  prefillFormWithFilterValues(filters)
  {
    // console.log("Apply filters to DOM", filters);

    for( let node of this.filternodes )
    {
      if (!(node.name in filters))
        continue;

      let inptype = node.nodeName == "INPUT" ? node.getAttribute("type") : "";

      if(inptype == "checkbox")
      {
        let val = filters[ node.name ];
        if (val)
        {
          if (val === true || val === false)
            node.checked = val;
          // else if Array.isArray(filters[ node.name ])
          else // assume we got a array of strings
            node.checked = filters[ node.name ].indexOf(node.value) > -1;
        }
      }
      else if(inptype == "radio")
        node.checked = filters[ node.name ] == node.value // ? true : false;
      else if(node.name in filters) //( filters[ node.name ] )
      {
        console.log(">>>>> setting", node.name, "to", filters[node.name]);
        node.value = filters[ node.name ];
      }
    }
  }

  // Set filter parameters in url
  updateURL(filters)
  {
    // console.log("Apply filters to URL", this.filters);

    let url = this.getURLForFilters(filters);

    history.replaceState(null, null/*WHBase.config.obj.title*/, url);
  }

  getFiltersFromForm(evt)
  {
    // FIXME: we should change to filters = { name: "", title: "", allselected: true }
    let filters = {};
    let filters_titles = {};

    let tags = [];

    //console.log(this.filternodes);

    for( let node of this.filternodes )
    {
      if(node.nodeName == "SELECT") //node.value != "") // <select> (pulldown) or <input type="text" />
      {
        if (node.value != "")
        {
          let title = node.options[node.selectedIndex].text;

          filters[ node.name ] = node.value;
          // FIXME: we could use .selectedOptions, but it might fail on IE11 ?
          filters_titles[ node.name ] = title; // node.value;

          tags.push({ node:  node
                    , name:  node.name
                    , value: node.value
                    , title: title
                    });
        }
      }
      else if (node.nodeName == "INPUT")
      {
        let inptype = node.getAttribute("type");


        //////////////////////////////////////////////////////
        if(inptype == "checkbox")
        {
          if(!node.checked)
            continue;

          if(!filters[ node.name ])
          {
            filters[node.name] = [];
            filters_titles[node.name] = [];
          }

          let title = this.getInputLabelText(node);

          filters[ node.name ].push( node.value );
          filters_titles[ node.name ].push( title );

          tags.push({ node:  node
                    , name:  node.name
                    , value: node.value
                    , title: title
                    });
        }
        else if(inptype == "radio")
        {
          if(!node.checked)
            continue;

          if (node.value != "") // ignore invalid (placeholder) or 'show all' settings
            filters[ node.name ] = node.value; //checked;

          let title = this.getInputLabelText(node);

          filters_titles[ node.name ] = title;

          tags.push({ node:  node
                    , name:  node.name
                    , value: node.value
                    , title: title
                    });
        }
        else // assume textual ("text", "search")
        {
          if (node.value != "")
          {
            filters[ node.name ] = node.value;
            filters_titles[ node.name ] = node.value;
            tags.push({ node:  node
                      , name:  node.name
                      , value: node.value
                      , title: node.value
                      });
          }
        }
        //////////////////////////////////////////////////////


      }
    }

    filters = { ...filters, ...this.options.hiddenfilters };


   //console.log("[filteredoverview] getFiltersFromForm", filters);

    if (this.options.debug)
    {
      console.log("[filteredoverview] getFiltersFromForm() result");
      console.log("[filteredoverview] Filters", filters);
      console.log("[filteredoverview] Filter titles", filters_titles);
    }


    //this.onFormFiltersUpdate(filters, evt);

    /*
    console.log("Filters after update", filters);
    console.groupEnd();
    */
    return { filters: filters
           , filters_titles: filters_titles
           , tags: tags
           };
  }

  getInputLabelText(node)
  {
    let title = "";

    if (node.hasAttribute("data-filtertagtitle")) // used by PDC, ACOI
    {
      title = node.getAttribute("data-filtertagtitle");
    }
    else if (node.id != "")
    {
      let labelnode = document.querySelector('label[for="'+node.id+'"]:not(:empty)');

      if (labelnode)
        title = labelnode.textContent;
      else
        title = "??"; // no label or there's a typo in the label's for attribute
    }

    return title;
  }

  resetFilters(evt)
  {
    console.info("[FilteredOverview] resetFilters()")
    this.form.reset();

    // if (evt) // prevent after resetting or whe'll block the reset
      // evt.preventDefault();

    if (this.options.defaultfilters)
      this.prefillFormWithFilterValues(this.options.defaultfilters);

    this.refreshResults(true);
  }


  doTracking(interactivechange)
  {
    if(interactivechange)
    {
      this.sendCurrentFiltersToGoogleAnalytics(interactivechange);
      this.sendCurrentFiltersToDataLayer(interactivechange);
    }

    this.filter_lastseenkeys = Object.keys(this.filters_titles);
  }


  sendCurrentFiltersToGoogleAnalytics()
  {
    console.info({ hitType:       "event"
              , eventCategory: this.options.trackingid
              , eventAction:   "filter"
              , eventLabel:    this.getURLForFilters(this.filters) // optional
              });

    if (window.ga)
    {
      ga( "send"
        , { hitType:       "event"
          , eventCategory: this.options.trackingid
          , eventAction:   "filter"
          , eventLabel:    this.getURLForFilters(this.filters) // optional
          });
    }
    else
      console.log("Not using Google Analytics");
  }


  // Used for Google Tagmanager
  sendCurrentFiltersToDataLayer()
  {
    if (!window.dataLayer)
      return;

    let dlevent = { event: "set_filters"
                  };
    Object.keys(this.filters_titles).forEach(key=>
    {
      // Convert an array (whether it's filled with strings or integer's) to a comma seperated string
      let val = this.filters_titles[key];
      if(Array.isArray(val))
        val = val.join(', ');

      dlevent['filter_' + key] = val;

      if(!this.filter_lastsettings[key] || this.filter_lastsettings[key] != val) //also send an explicit event for just this filter
      {
        this.filter_lastsettings[key] = val;
        dataLayer.push({ event: 'set_filter', filtername: key, filtervalue: val});
      }
    });
    this.filter_lastseenkeys.filter(key => !(key in this.filters_titles)).forEach(key => //this key went away
    {
      dataLayer.push({ event: 'set_filter', filtername: key, filtervalue: ''});
    });

    dataLayer.push(dlevent);

    // if (this.options.debug)
    // console.info(dataLayer);
  }



  async refreshResults(interactivechange)
  {
    if (this.options.debug)
      console.info("refreshResults");

    if (this.options.method == "rpc")
      return this.refreshResultsFromRPC(interactivechange);
    else if (this.options.method == "clientside")
      this.refreshResultsByFiltering(interactivechange);
    else if (this.options.method == "custom")
      this.refreshResultsByCallback(interactivechange);
  }

  async refreshResultsByCallback(interactivechange)
  {
    this.__refreshShared(interactivechange);
    // this.options.onfilterchange();
  }


  async refreshResultsByFiltering(interactivechange)
  {
    //console.log("Filtering", this.items.length, "items");

    this.__refreshShared(interactivechange);

    let totalfound = 0;


    //Do text search server-side /////////////////////////////////////////////////////////////////////////////////////////
    let rpc_field_used = false;
    if (this.options.fields_rpc.length > 0)
    {
      for (let key of this.options.fields_rpc)
      {
        if (key in this.filters)
          rpc_field_used = true;
      }
    }

    // if (this.filters.query)
    if (rpc_field_used)
    {
      console.info("An field which requires the RPC is used.")
      if (!this.options.rpc)
        this.options.rpc = new JSONRPC();

      if (!this.options.rpcsearchfunction)
      {
        console.error("rpcsearchfunction must be specified when fields_rpc is used.");
        return;
      }

      // Copy the filter fields which must go through the RPC
      let fields = {};
      for (let key of this.options.fields_rpc)
      {
        fields[key] = this.filters[key];
      }

      // If the page passed the "filteredoverview_folderurl" we can use that
      // to pass to the RPC in case restrict_url needs to be used on the Consilio.
      // This makes searches quicker

      let sourceurl = whintegration.config.obj.filteredoverview_folderurl;
      if (!sourceurl)
        sourceurl = (document.location.protocol + "//" + document.location.host + document.location.pathname);

      if (this.options.debug_rpc)
      {
        console.info("Calling RPC", { rpcfunc:   this.options.rpcsearchfunction
                                    , fields:    fields
                                    , sourceurl: sourceurl
                                    });
      }

      // await this.rpc.async('SearchWords', words);
      let searchresults = await this.options.rpc.promiseRequest(this.options.rpcsearchfunction
                , [ fields
                  , sourceurl
                  ]
                );
      if (this.options.debug_rpc)
      {
        console.info("[FO] Result for rpc FindProjects", searchresults);
      }

      //console.log("RPC RESULTS", searchresults);
      this.rpc_matchesids = searchresults.matchesids;
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (this.options.debug)
      console.log("[filteredoverview] refreshResultsByFiltering", this.filters);

    for(let item of this.items)
    {
      console.log("asked match for", item);

      let ismatch = this.isMatch(item.filterdata, this.filters);
      //console.log(ismatch, item);
      if (this.options.debug)
        console.log(ismatch, item);

      item.node.classList[ismatch ? "remove" : "add"]("notmatching");

      if(ismatch)
        ++totalfound;
    }


    if (this.filters.sort)
    {
      if (this.options.sortorderings)
      {
        let orderids = this.options.sortorderings[this.filters.sort];

        if (this.options.debug_ordering)
        {
          console.log("Sort by", this.filters.sort);
          console.log("Sort ordering ids", orderids);
        }

        // FIXME: implement a cleaner way, continer as option?
        let container = this.items[0].node.parentNode;

//while(container.firstChild)
//  container.removeChild(container.firstChild);

        for(let id of orderids)
        {
          let found = false;

          // Find the item with that ID (FIXME:This should be done upon initialization instead of here....)
          for (let item of this.items)
          {
inner:
            if (item.filterdata.id == id)
            {
              container.appendChild(item.node);
              found = true;
              break inner;
            }
          }

          if (!found)
            console.error("Didn't find #"+id);
        }
      }
      else
        console.error("Please add sortorderings field to FilteredOverview options.");
      //this.doSortItems(this.filters.sort);
    }


    this.setFeedback(totalfound);

    if (window.bLazy)
      window.bLazy.revalidate();
  }



/*
  doSortItems(sortby)
  {
    switch(sortby)
    {
      // from cheap to expensive
      case "oldest":
        this.items.sort(function(a,b) { return a.filterdata.published > b.filterdata.published ? 1 : -1; });
        break;

      // large to small
      case "newest":
        this.items.sort(function(a,b) { return a.filterdata.published < b.filterdata.published ? 1 : -1; });
        break;

      case "lastupdated":
        this.items.sort(function(a,b) { return a.filterdata.modified < b.filterdata.modified ? 1 : -1; });
        break;

      default:
        alert("unknown sort method.");
        break;
    }

    // FIXME: implement a cleaner way, continer as option?
    let container = this.items[0].node.parentNode;
    //console.log("Items container", container);
    for (let item of this.items)
      container.appendChild(item.node);
  }
*/

  isTextualFieldMatchIn(findtext, fulltext)
  {
    let matchparts = findtext.toLowerCase().split(" ");

    for (let part of matchparts)
    {
      part = part.trim();
      let ismatch = part != "" && fulltext.toLowerCase().indexOf(part) > -1;

      if (ismatch)
        return ismatch;
    }

    return false;
  }


  isMatch(itemfilterdata)
  {
    let doesnotmatch = false;


    for(let key in this.filters)
    {
      if (this.options.fields_notforfiltering.indexOf(key) > -1)
        continue;

      let filtervalue = this.filters[key];

      if (this.options.fields_rpc.indexOf(key) > -1)
      {
        console.log("handing rpc field", key);

        if (this.rpc_matchesids.indexOf(itemfilterdata.id) == -1)
        {
          console.log(itemfilterdata.id, "isn't in the set of matches"); // this.rpc_matchesids
          doesnotmatch = true;
        }

        continue;
      }


      let itemvalue = itemfilterdata[key];


      if(key in this.options.filtermatchcallbacks)
      {
        // We got a custom handling for this field
        return this.options.filtermatchcallbacks[key](filtervalue, itemvalue);
      }

      // We have to do the match ourselves. This can only be done if this field also exists in the itemfilterdata.
      if (!(key in itemfilterdata))
      {
        console.log("Item data missing field '"+key+"'");
        continue;
      }


      if (this.options.fields_textmatch.indexOf(key) > -1)
      {
        let ismatch = this.isTextualFieldMatchIn(filtervalue, itemvalue)
        doesnotmatch = !ismatch;
      }
      else if(Array.isArray(filtervalue) && Array.isArray(itemvalue)) // Find multiple values in multiple values
      {
        let found = false;

        if (itemvalue.length > 0)
        {
          let numeric = typeof itemvalue[0] == "number"; // is the expected value numeric?
          // console.log(key, "is numeric", numeric);
          for( let i = 0; i < filtervalue.length; ++i )
          {
            let single_filter_value = numeric ? parseInt(filtervalue[i]) : filtervalue[i];
            if (itemvalue.indexOf(single_filter_value) > -1)
            {
              found = true; // At least one tag matched
              break;
            }
          }
        }

        if (!found)
        {
          doesnotmatch = true;
          // console.log("not matching on", key, "value", itemvalue, "not found in", filtervalue);
        }
      }
      else if(Array.isArray(itemvalue))
      {
        // lookup single value (pulldown or text) in array
        if (itemvalue.indexOf(filtervalue) == -1 )
        {
          doesnotmatch = true;
          // console.log("not matching on", key, "value", itemvalue, "not found in", filtervalue);
        }
      }
      else if(Array.isArray(filtervalue))
      {
        if (filtervalue.indexOf(itemvalue) == -1)
        {
          doesnotmatch = true;
          // console.log("not matching on", key, "value", itemvalue, "not found in", filtervalue);
        }
      }
      else
      {
        // lookup single value (pulldown or text) in a non-array (number of string)
        // (we assume the filter contains a string because a pulldown doesn't know integers)
        let filtervalue_cast = (typeof itemvalue == "number" ? parseInt(filtervalue) : filtervalue);
        //console.info("match", matchvalue, typeof(matchvalue));
        if (itemvalue != filtervalue_cast)
        {
          doesnotmatch = true;
          // console.log("not matching on", key, "value", itemvalue, "not found in", filtervalue_cast);
        }
      }
    }

    if (this.options.finalize_itemmatch)
    {
      if (!this.options.finalize_itemmatch(itemfilterdata))
      {
        console.info("match disabled by finalize_itemmatch");
        return false;
      }
    }

    return !doesnotmatch;
  }




  async __refreshShared(interactivechange)
  {
    let filters = this.getFiltersFromForm();
    this.filters = filters.filters;
    this.filters_titles = filters.filters_titles;
    this.tags = filters.tags;

    // Give a change to manipulate the filters (or for method: "custom" to refresh/filter the list)
    if (this.options.onfilterchange)
      this.options.onfilterchange(this.filters);

    this.doTracking(interactivechange);

    //console.log("refreshResults()", this.filters);

    this.syncFilterTags();

    this.updateURL(filters.filters);
  }

  async refreshResultsFromRPC(evt)
  {
    let interactivechange = !!evt;
    this.__refreshShared(interactivechange);

    if (this.options.debug)
      console.log("this.filters", this.filters);

    let rpcfilters = this.filters;
    // console.log("Calling", this.options.rpcsearchfunction, rpcfilters);

    // Callback. Usefull for example for BOOLEAN options (since the FilteredOverview doesn't internally have a BOOLEAN type)
    if (this.options.getfiltersforrpc)
      rpcfilters = this.options.getfiltersforrpc(this.filters);

    if (this.options.debug_rpc)
      console.info("[FilteredOverview] Calling", this.options.rpcsearchfunction, "with", rpcfilters);

    let results = await this.options.rpc.async(this.options.rpcsearchfunction, rpcfilters);

    if (this.options.debug_rpc)
      console.info("[FilteredOverview] RPC response", results);
    // console.log(results);

    if (this.options.ongotrpcresults)
      this.options.ongotrpcresults(results);

    this.resultsnode.innerHTML = results.resultshtml;

    this.setFeedback(results.totalcount);

    if (this.options.onafterrefresh)
    {
      //console.info("[FilteredOverview] Calling onafterrefresh");
      this.options.onafterrefresh();
    }

    if (window.bLazy)
      window.bLazy.revalidate();
  }


  ////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Tags
  //

  syncFilterTags()
  {
    if (this.options.debug_tags)
      console.log("[filteredoverview] syncFilterTags", this.tags);


    let use_tags = this.tags;
    if (this.options.tags_remap)
      use_tags = this.options.tags_remap([...this.tags]); // pass a clone of the tags object


    let filtertagscontainer = document.querySelector(".filtertags__items");
    if (!filtertagscontainer)
      return;

    let tagcontainer = document.createDocumentFragment();

    // Just generate ALL tags
    for (let tag of use_tags)
    {
      if (this.options.fields_hidetag.indexOf(tag.name) > -1)
      {
        console.log("SKIP");
        continue; // we don't want any tags generated for this field
      }

      let item = document.createElement("button");
      item.setAttribute("type", "button");
      item.__tag = tag;

      item.className = "filtertags__item";

      if (tag.color)
      {
        item.style.borderColor = tag.color;
      }

      let title = document.createElement("div");
      title.className = "filtertags__item__title";
      title.textContent = tag.title;

      item.appendChild(title);
      tagcontainer.appendChild(item);
    }

    filtertagscontainer.innerHTML = "";
    filtertagscontainer.appendChild(tagcontainer);

/*
    // FIXME: type="reset" so screenreader can announce it? might currently cause another extra reset event though.
    let resetfilterbutton = <button class="filteredoverview-action--resetfilters">Clear all filters</button>;
    resetfilterbutton.addEventListener("click", evt => this.doClearFilters(evt));
    filtertagscontainer.appendChild(resetfilterbutton);
*/
  }

  doClearFilters(evt)
  {
    /*
    this.filters = {};
    this.prefillFormWithFilterValues(this.filters);
    */
    // evt.preventDefault();
    this.resetFilters(evt);
  }



  doCheckForTagRemoval(evt)
  {
    let tagnode = dompack.closest(evt.target, ".filtertags__item");
    if (!tagnode)
      return;

    evt.preventDefault();

    if (this.options.onremovetag)
    {
      if (this.options.onremovetag(tagnode.__tag))
      {
        this.refreshResults(true);
        return;
      }
    }

    let node = tagnode.__tag.node; // get the form field node

    if (!node)
    {
      console.error("Failed to find field/option");// belonging to the removed tag", fieldname, fieldvalue);
      return;
    }

    this.__resetFormNodeValue(node);

    this.refreshResults(true);
  }

  __resetFormNodeValue(node)
  {
    let defaultval = this.options.defaultfilters[node.name];

    if (node.tagName == "SELECT")
    {
      if (defaultval)
        node.value = defaultval;
      else
      {
        let firstitem = node.querySelector("option");
        if (firstitem)
          node.value = firstitem.value;
      }
      // node.value = "";
    }
    else if (node.tagName == "INPUT" && ["checkbox", "radio"].indexOf(node.getAttribute("type")) > -1)
      node.checked = defaultval ?? false;
    // else if (node.tagName == "INPUT" && ["input", "search"].indexOf(node.getAttribute("type")) > -1)
    else if (node.tagName == "INPUT") // assume anothing other is textual
      node.value = defaultval ?? "";
  }



  ////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Misc helper functions
  //

  isFilterTheDefault(filters)
  {
    return object_equals(this.options.defaultfilters, filters);
  }

  /*
  isFilterActive()
  {
    return Object.keys(this.filters).length;
  }
  */

  isFilterActive()
  {
    //return Object.keys(this.filters).length > 0;

    // Lookup if any of the fields in this.filters can be considered "active".
    // (not listed in the fields_dontcountasactive array)
    let keynames = Object.keys(this.filters);

    let active = false;
    for (let keyname of keynames)
    {
      if (this.options.fields_dontcountasactive.indexOf(keyname) == -1
          && this.options.defaultfilters[keyname] != this.filters[keyname]
         )
      {
        // console.log("active because of", keyname);
        active = true;
        break;
      }
    }

    return active;
  }

  getUrlParam(name)
  {
    var urlparamstr = location.search.replace(/\+/g,"%20");
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(urlparamstr))
      return decodeURIComponent(name[1]);
    return "";
  }

  setFeedback( totalfound, totalshown )
  {
    let filteractive = this.isFilterActive();

    document.documentElement.classList[ filteractive ? "add" : "remove"]("filteredoverview--filtersactive");

    document.documentElement.classList[ totalfound == 0 ? "add" : "remove"]("filteredoverview--noresults");
    document.documentElement.classList[ totalfound == 1 ? "add" : "remove"]("filteredoverview--singleresult");
    document.documentElement.classList[ totalfound  > 1 ? "add" : "remove"]("filteredoverview--multipleresults");
/*
    if (this.feedbacknode)
    {
      if (filteractive)
      {
        if (totalfound == 0)
          this.feedbacknode.innerText = getTid("tue:webdesigns.site.js.filteredoverview.results-none");
        else if (totalfound == 1)
          this.feedbacknode.innerText = getTid("tue:webdesigns.site.js.filteredoverview.results-single");
        else if (totalfound > 1)
          this.feedbacknode.innerText = getTid("tue:webdesigns.site.js.filteredoverview.results-multiple", totalfound);
      }
      else
      {
        if (totalfound == 0)
          this.feedbacknode.innerText = getTid("tue:webdesigns.site.js.filteredoverview.results-nofilter-none");
        else if (totalfound == 1)
          this.feedbacknode.innerText = getTid("tue:webdesigns.site.js.filteredoverview.results-nofilter-single");
        else if (totalfound > 1)
          this.feedbacknode.innerText = getTid("tue:webdesigns.site.js.filteredoverview.results-nofilter-multiple", totalfound);
      }
    }
*/
    if (this.feedbacknode)
    {
      if (filteractive)
      {
        if (totalfound == 0)
          this.feedbacknode.innerHTML = getHTMLTid("tue:webdesigns.site.js.filteredoverview.results-none");
        else if (totalfound == 1)
          this.feedbacknode.innerHTML = getHTMLTid("tue:webdesigns.site.js.filteredoverview.results-single");
        else if (totalfound > 1)
          this.feedbacknode.innerHTML = getHTMLTid("tue:webdesigns.site.js.filteredoverview.results-multiple", totalfound);
      }
      else
      {
        if (totalfound == 0)
          this.feedbacknode.innerHTML = getHTMLTid("tue:webdesigns.site.js.filteredoverview.results-nofilter-none");
        else if (totalfound == 1)
          this.feedbacknode.innerHTML = getHTMLTid("tue:webdesigns.site.js.filteredoverview.results-nofilter-single");
        else if (totalfound > 1)
          this.feedbacknode.innerHTML = getHTMLTid("tue:webdesigns.site.js.filteredoverview.results-nofilter-multiple", totalfound);
      }
    }

    this.setTeaserResultCount(totalfound, totalshown);
  }


  getURLForFilters(filters)
  {
    if (this.isFilterTheDefault(filters))
    {
      // console.info("Filters default");
      return window.location.pathname;
    }

    // console.info("Filters NOT default");
    return getURLWithRecordApplied(filters, Object.keys(this.options.hiddenfilters));
  }
}


function object_equals( x, y ) {
  if ( x === y ) return true;
    // if both x and y are null or undefined and exactly the same

  if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
    // if they are not strictly equal, they both need to be Objects

  if ( x.constructor !== y.constructor ) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

  for ( var p in x ) {
    if ( ! x.hasOwnProperty( p ) ) continue;
      // other properties were tested using x.constructor === y.constructor

    if ( ! y.hasOwnProperty( p ) ) return false;
      // allows to compare x[ p ] and y[ p ] when set to undefined

    if ( x[ p ] === y[ p ] ) continue;
      // if they have the same strict value or identity then they are equal

    if ( typeof( x[ p ] ) !== "object" ) return false;
      // Numbers, Strings, Functions, Booleans must be strictly equal

    if ( ! object_equals( x[ p ],  y[ p ] ) ) return false;
      // Objects and Arrays must be tested recursively
  }

  for ( p in y )
    if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) )
      return false;
        // allows x[ p ] to be set to undefined

  return true;
}


function getURLWithRecordApplied(filters, ignore_fieldnames = [])
{
  let urlparams = [];

  //console.info("getURLWithRecordApplied", filters, ignore_fieldnames);

  for(let name of Object.keys(filters))
  {
    // skip fields we need to ignore
    if (ignore_fieldnames.includes(name))
      continue;

    let val = filters[name];

    if(["number", "string"].indexOf(typeof val) > -1)
    {
      if (val != "")
        urlparams.push(name + "=" + encodeURIComponent(val));
    }
    else if(typeof val == "boolean")
    {
      if (val)
        urlparams.push(name);
    }
    else if (Array.isArray(val))
    {
      let encodedvals = [];
      for(let valitem of val)
        encodedvals.push(encodeURIComponent(valitem));

      if (val.length > 0)
        urlparams.push( name + "=" + encodedvals.join(","));
    }
  }

  window.enabled_sitedebug = location.href.indexOf("debug") > -1;
  if (window.enabled_sitedebug)
    urlparams.push("debug");

  let url = "";
  if (urlparams.length > 0)
    url = window.location.pathname + "?" + urlparams.join("&");
  else
    url = window.location.pathname; // set absolute path so we remove the "?"

  //url += location.hash; // keep hash on the URL

  return url;
}
