import "./folderwithfilters.scss";
import "./folderwithfilters-filters.scss";

import "./folderwithfilters-grid.scss";
import "./folderwithfilters-list.scss";

import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "../../components/filteredoverview";
import getTid from "@mod-tollium/js/gettid";
import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";


dompack.onDomReady(initFilters);


function initFilters()
{
  if (!document.documentElement.classList.contains("page--folderwithfilters"))
    return;

  console.log("Initializing folder with filters FilteredOverview");

  let allforms = [];

  let resultsnode = document.querySelector(".folderwithfilters__items");

  // let formnode = document.getElementById("folderwithfiltersform");
  // let formnode = document.querySelector(".page__headerfilters");
  let filterbar = document.body.querySelector(".header-filterbar");
  if (filterbar)
    allforms.push(filterbar);

  let expandedfilters = document.querySelector(".filteredoverview__form");
  if (expandedfilters)
    allforms.push(expandedfilters);

  let tabsbar = document.querySelector(".folderwithfilters-tabs");
  if (tabsbar)
    allforms.push(tabsbar); // not a <form> perse, but filteredoverview will look for controls within anyway


  let mainform = allforms.shift();

  let settings =
          { trackingid:             "folderwithfilters" // name for analytics (and debug)
          , additionalform:         allforms
          , node_results:           resultsnode

          , items:                  document.querySelectorAll(".projectblock")
          , getfloatingteasertext:  getFloatingTeaserText

          , method:                 "clientside"
          , rpc:                    new JSONRPC({ url: "/wh_services/tue/rpc/" })
          , rpcsearchfunction:      "FindItems"
          , fields_rpc:             ["query"]

          , debug: true
          //, debug_rpc: true
          };

  console.info("Formnode", mainform);
  console.info("Settings", settings);

  window.fo_projects = new FilteredOverview(mainform, settings);
  window.fo_projects.refreshResults();

  resultsnode.style.display = ""; // remove display: none;
}


function getFloatingTeaserText(totalfound, totalshown)
{
  if( totalfound == 1 )
    return getTid('tue:webdesigns.site.js.filteredoverview.tease-result', totalfound);
  else if( totalfound > 1 )
    return getTid('tue:webdesigns.site.js.filteredoverview.tease-results', totalfound);
  else
    return getTid("tue:webdesigns.site.js.filteredoverview.tease-noresults");
}
