import * as dompack from "dompack";
import * as forms from '@mod-publisher/js/forms';
import UploadField from '@mod-publisher/js/forms/fields/upload';

import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
//import RTDField from '@mod-publisher/js/forms/fields/rtd';

import "./what-input";

//import * as googleRecaptcha from "@mod-publisher/js/captcha/google-recaptcha";

// Load neutral styling (optional, but you'll need to supply your own styling for some of the fields below if you skip this)
import '@mod-publisher/js/forms/themes/neutral';


import './buttons.scss';
import './checkbox-radio.scss';
import './checkbox-switch.scss';
import './textual-input.scss';
import './formlayout.scss';
import './pulldown.scss';

import './uploadfields.scss';


// Enable forms and our builtin validation
forms.setup({ validate:true });

// Replaces upload fields with a nicer and edit-supporting version
dompack.register(".wh-form__upload", node => new UploadField(node));

// Enable the imgedit and/or rtd fields:
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));
//dompack.register(".wh-form__rtd", node => new RTDField(node));


//dompack.register(".canvasupload", node => new CanvasUploadField(node));


// enable the line below AND the googleRecaptcha import if you want to use this recaptcha. you'll also need to enable it in the site profile
// googleRecaptcha.setupGoogleRecaptcha();

/*
// Styled (native) pulldown: add wrapper around select element + arrow for css-styling
dompack.register(".wh-form select", selectnode => {
  let wrappernode = <div class="wh-form__pulldown-styled" />;
  selectnode.parentNode.insertBefore(wrappernode, selectnode);
  wrappernode.appendChild(selectnode);
  wrappernode.appendChild(<span class="arrow fas fa-angle-down" />);
});
*/
