import * as dompack from 'dompack';
import "./deeplinks.scss";


export function activate()
{
  // Positioning & sizing anchor
  let wrapper = document.createElement("div");
  wrapper.className = "deeplinks-wrapper";

  // Wrapper containing the items, but which also removes itself out of the flow.
  // (but using it's parent to inherit it's width from)
  let container = document.createElement("div");
  container.className = "deeplinks";

  let headings = document.querySelectorAll("h2");

  if (headings.length < 2)
    return;

  for (let node of headings)
  {
    let title = node.innerText;

    let headerid;

    //if (node.id == "")
    //{
      let headerbase = convertTextToSafeID(title);

      // Handle duplicate header titles
      headerid = headerbase;
      let count = 0;
      while(document.getElementById(headerid))
      {
        count++;
        headerid = headerbase + "_" + count;
      }

      // node.setAttribute("id", headerid); // apply this id to the <h2>
    //}
    //else
    //  headerid = node.id;

    //node.classList.add("deeplinktarget");


    // Create an element to jump to
    // (we need a seperate element which we can offset so when jumping to our H2 isn't below the sticky header)
    let targetelement = document.createElement("span");
    targetelement.className = "deeplinktarget";
    targetelement.id = headerid;
    node.insertBefore(targetelement, node.firstChild);


    // Create an item for the deeplinks menu ------------
    var anchor = document.createElement("a");
    anchor.className = "deeplinks__item";
    //anchor.appendChild(document.createTextNode(title));
    anchor.setAttribute("href", "#" + headerid);

    var anchorlabel = document.createElement("div");
    anchorlabel.className = "deeplinks__item__label";
    anchorlabel.innerText = title;
    anchor.appendChild(anchorlabel);
    // --------------------------------------------------


    container.appendChild(anchor);
  }

  wrapper.appendChild(container);

  document.querySelector(".page__contentarea").appendChild(wrapper);
}


// originally written fom PThU webdesign
function convertTextToSafeID(name)
{
  /*
  ID and NAME tokens must begin with a letter ([A-Za-z]) and may be followed by any number of letters, digits ([0-9]), hyphens ("-"), underscores ("_"), colons (":"), and periods (".").
  */
  name = name.toLowerCase();
  return name.replace(/[^a-z0-9]/g, function(s)
            {
              var c = s.charCodeAt(0);
              if (c == 32)
                return '-';

              return "";
            });
}
