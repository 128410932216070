import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "../../components/filteredoverview";
import * as whintegration from "@mod-system/js/wh/integration";
import * as dompack from "dompack";
import getTid from "@mod-tollium/js/gettid";

import "./projectsoverview.scss";
import "./projectsoverview_header.scss";
import "./projectsoverview_foldout.scss";
import "./project-tile.scss";

//import "../../components/multiselect";



function getFloatingTeaserText(totalfound, totalshown)
{
  if( totalfound == 1 )
    return getTid('tue:webdesigns.site.js.filteredoverview.tease-result', totalfound);
  else if( totalfound > 1 )
    return getTid('tue:webdesigns.site.js.filteredoverview.tease-results', totalfound);
  else
    return getTid("tue:webdesigns.site.js.filteredoverview.tease-noresults");
}


function initProjectsFilters()
{
  if (!document.documentElement.classList.contains("page--projectsoverview"))
    return;

  let formnode = document.body.querySelector(".projects__filters");

  let usenewtags = whintegration.config.obj.overviewpage.newtags;

  let settings =
          { trackingid:        "projects"
          , additionalform:    document.querySelector(".header-filterbar")
          , node_results:      document.querySelector(".projects__results")
          , resultsanchor:     document.querySelector(".filteredoverview__feedbackandviewbar") //".filteredoverview__resultsanchor")
        //, resultsanchor:          document.getElementById("publicationsoverview-searchresults")
        //, items:                  document.querySelectorAll(".searchresult")

          , method:            "rpc"
          , rpc:               new JSONRPC({ url: "/wh_services/tue/rpc/" })
          , rpcsearchfunction: "SearchProjects"
          , hiddenfilters:     { newtags: usenewtags }

          , debug:                 true
          , debug_rpc:             true

          , getfloatingteasertext: getFloatingTeaserText
          };

  // Send the filters that were used when landing on this page.
  // (we only get here if there are filters specified on the URL)
  // Since the initial landing has the results generated into the page serverside we also send stats here.
  // let filters = getFiltersValue();
  //sendSearchEvent('', null /*initial search*/, resultstotal);
  window.fover = new FilteredOverview(formnode, settings);
  window.fover.refreshResults();
}


dompack.onDomReady(initProjectsFilters);

