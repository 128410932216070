import "./cta.scss";
import * as dompack from "dompack";

let observer = new ResizeObserver((entries) => handleResizesOfCTA(entries));


/*

- Because a line-height may take up to 24px the whitespace above an image may be up to 24px
- It may be nice to distribute the extra space somehow... or tweak line-heights slightly ??

*/


dompack.register(".widget-cta", initCTA);

function initCTA(node)
{
  console.info(node);;
  observer.observe(node);
}

function handleResizesOfCTA(entries)
{
  // console.info("OBSERVE", entries);

  // let idx = 0;
  for (let entry of entries)
  {
    // "focus-bottom-right"
    let appearance = getComputedStyle(entry.target).getPropertyValue("--appearance");
    // console.log(appearance);
    if (appearance == '"focus-bottom-right"')
    {
      //console.log("skipping");
      handleCTA(entry);
      continue;
    }

    // console.log("handling");
/*
    if (idx > 0)
      return;

    idx++;
*/
  }


}

function handleCTA(entry)
{
  let widgetnode = entry.target;
  let new_width = entry.contentRect.width;
  console.info(widgetnode, "width is", new_width);

  if (widgetnode.__prevwidth && widgetnode.__prevwidth == new_width)
    return;


  let imgwrapper = widgetnode.querySelector(".widget-cta__imagewrapper");
  widgetnode.style.setProperty("--cta-img-width", imgwrapper.clientWidth + "px");


  if (!widgetnode.__update)
    widgetnode.__update = 0;
  else
    widgetnode.__update++;

  widgetnode.__prevwidth = new_width;

  if (widgetnode.__update > 10)
    return;

  console.group("Fixup CTA panel height");


  let too_high = null;
  let too_flat = null;
  let previous_set_height = null;

  let iteration = 0;
  let groupstarted = false;
  while(iteration < 8)
  {
    iteration++;

    if (groupstarted)
      console.groupEnd();

    console.group("Iteration #%i", iteration);
    groupstarted = true;

    // console.log("WIDGET container", entry.target);
    // The browser probably doesn't want to stretch the container
    // due to having to reflow text around our float (to prevent extra reflows).
    // So we have to do an additional reflow.

    // We need to fix the height of "widget-cta__text" to encapsulate the
    // bottom of the last content. (the .widget-cta__description.rtdcontent)
    let lastchild = widgetnode.querySelector(".widget-cta__description");
    let colorpanel = widgetnode.querySelector(".widget-cta__content-background");

    let description_bounds = lastchild.getBoundingClientRect();
    let panelrect = colorpanel.getBoundingClientRect();
    let bottom_y = description_bounds.bottom;
    let top_y = panelrect.top; //entry.contentRect.top;
    let neededheight_to_wrap_text = bottom_y - top_y;


    if (previous_set_height && (panelrect.height > previous_set_height))
    {
      console.warn("Previously set height", previous_set_height, "was stretched to", panelrect.height, "by webbrowser");

      too_flat = panelrect.height;

      if (too_flat == too_high)
      {
        // This may be the best bet even though we did get a lof of whitespace at the bottom
        console.info("The browser stretched the height back to the value that had additional whitespace. (probably due to line-height > additional-whitespace-threshold)");
        break;
      }
    }

    // console.info(lastchild.getBoundingClientRect());
    // console.info("Colorpanel top", top_y);
    // console.info("Bottom of text", bottom_y);

    console.info("Colorpanel height", panelrect.height);
    // console.log("Text height", description_bounds.height);
    console.info("Colorpanel required height to fit text", neededheight_to_wrap_text); // required height

    // Still in confortable margin-of-error?
    // Do nothing, otherwise whe'll get a lot of jumping around of the height and text wrapping.
    let height_diff = panelrect.height - neededheight_to_wrap_text;

    if (iteration == 1 && (height_diff > 6 || height_diff < -6) && colorpanel.style.minHeight != "")
    {
      console.info("FIRST ITERATION - doing RESET");
      // First iteration and already the height deviates too much?
      // Just trigger a reflow
      colorpanel.style.minHeight = "";
      widgetnode.style.setProperty("--height-compensation", "0px");
    }

    if (height_diff > 6 && iteration < 8) // up till iteration 5 we are allowed to shrink
    {
      console.log("Too much whitespace (%i). Triggering reflow", height_diff);

      // let newheight = ((too_flat ?? panelrect.height) + (too_high ?? neededheight_to_wrap_text)) / 2 + "px";

      too_high = panelrect.height;

      let newheight = (  (too_flat ?? panelrect.height)
                       + (too_high ?? neededheight_to_wrap_text)
                      ) / 2;
      newheight = Math.ceil(newheight);

      console.log( "Trying height", newheight
                 , " - rounded value between"
                 , (too_flat ?? panelrect.height)
                 , "and"
                 , (too_high ?? neededheight_to_wrap_text)
                 );

      // try something inbetween
      colorpanel.style.minHeight = newheight;
      widgetnode.style.setProperty("--height-compensation", "0px");
      previous_set_height = newheight;


      continue; // restart this process
    }
    else if (height_diff < -6)
    {
      console.log("Not enough space at all (diff %i). Stretching and triggering reflow.", height_diff)

// console.log("BLUB", too_high ?? panelrect.height);
      // try something inbetween

      too_flat = panelrect.height;

      let newheight = (  (too_flat ?? panelrect.height)
                       + (too_high ?? neededheight_to_wrap_text)
                      ) / 2;
      newheight = Math.ceil(newheight);

      console.log("Trying height", newheight
                 , "- rounded value between"
                 , (too_high ?? panelrect.height)
                 , "and"
                 , (too_high ?? neededheight_to_wrap_text)
                 );


      colorpanel.style.minHeight = newheight + "px";
      widgetnode.style.setProperty("--height-compensation", "0px");
      previous_set_height = newheight;

      continue; // restart this process
    }
    else if (height_diff < 0.5)
    {
      console.log("Exactly correct height");
      // return;
      break;
    }
    else if (height_diff > -3 && height_diff < 3)
    {
      console.log("Height diff (%i) is neglicable", height_diff, height_diff);
      break;
    }
    else
    {
      console.info("Forcing height to", neededheight_to_wrap_text);
      console.log("Tweaking the height by", height_diff, "px  AND compensating float space to prevent reflow/wrapping changed.");


      colorpanel.style.minHeight = neededheight_to_wrap_text + "px";

      // widgetnode.style.setProperty("--height-compensation", -height_diff + "px");

      console.info( colorpanel.style.getPropertyValue("--height-compensation") );

      console.log("--height-compensation", -height_diff, "on", colorpanel);
      break;
    }
  }

  if (groupstarted)
    console.groupEnd(); // end iteration group

  console.groupEnd();
}
