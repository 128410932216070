import "./pageheader-textual.scss";
import "./pageheader-default.scss";
import "./pageheader-bottombar.scss";
import "./pageheader-bottombar-searchbar.scss";
import "./header-filters.scss";
import "./navigationpath.scss";
import "./navigationpath-sticky.scss";

import "./pageheader-filterheader.scss";

import * as dompack from "dompack";
import SlideShow from "../components/slideshow";



let observer = null; //: IntersectionObserver;
let navpath_sticky = false;



dompack.onDomReady(init);

dompack.register(".page-header__slideshow", initHeaderSlideshow);


function init() {
  observer = new IntersectionObserver(doHandleIntersections
        , { threshold: 1
        //, rootMargin: "-20px 0px 0px 0px"
          , rootMargin: "-1px 0px 0px 0px"
          });

  /*
  let navpath = document.querySelector(".page-header__navpath");
  if (!navpath)
    return;

  console.info("*** observing navpath intersection");
  observer.observe(navpath);
  */

  // In tablet/desktop mode the menubar is sticky.
  // As soon as it sticks the navpath should also stick because
  // when scrolling further the navpath will scrolling below the menubar.
  // (because the navpath is shown directly below the menubar)
  let menubar = document.querySelector(".header-menubar");
  // menubar.style.scrollMarginTop = "-10px";
  if (!menubar)
    return;

  // console.info("*** observing ");
  observer.observe(menubar);

}


// function doHandleIntersections(entries: IntersectionObserverEntry[]) {
function doHandleIntersections(entries) {
  console.log("doHandleIntersections");

  for (const entry of entries) {

    console.log("isIntersecting", entry.isIntersecting);
    // console.info(entry);

    if (entry.isIntersecting)
    {
      if (navpath_sticky)
      {
        console.info("Move navpath back into the page-header.");

        navpath_sticky = false;

        let navpath_placeholder = document.querySelector(".page-header__navpath--placeholder");
        let navpath = document.querySelector(".page-header__navpath:not(.page-header__navpath--placeholder)"); // entry.target

        // console.log("Placeholder navpath", navpath_placeholder);
        // console.log("Navpath", navpath);
        navpath_placeholder.replaceWith(navpath);

        document.documentElement.classList.remove("navpath--sticky");
      }
    }
    else if (!navpath_sticky) // Make it sticky
    {
      console.info("Moving navigation path to .page__body__stickycontent");

      navpath_sticky = true;

      let navpath = document.querySelector(".page-header__navpath"); // entry.target
      navpath.replaceWith(<div class="page-header__navpath page-header__navpath--placeholder"></div>);

      // let pagebody = document.querySelector(".page__body");
      // pagebody.insertBefore(entry.target, pagebody.firstChild);

      let pagebody = document.querySelector(".page__body__stickycontent__content");
      console.info("sticky container", pagebody);
      pagebody.appendChild(navpath);

      document.documentElement.classList.add("navpath--sticky");

      // entry.target.classList.add("page-header__navpath--sticky");
    }
    console.info(entry);
  }

    //if (entry.isVisible && entry.tagName == "chat-message-media__imageplaceholder") {
    //if (entry.isIntersecting) {// && entry.target.tagName == "chat-message-media__imageplaceholder") {


  // Move the navpath outside the page-header
  // into the main.page__body so we can position it sticky
  // to the top of the page content.
  // This way it'll always stick to the menubar.
/*
  let pagebody = document.querySelector(".page__body");
  pagebody.insertBefore(pagebody, pagebody.firstChild);
*/
}



function initHeaderSlideshow(node)
{
  let slides = node.querySelectorAll(".carrousel__cell");
  if (slides.length < 2)
    return;

  let buttons = document.querySelectorAll(".page-header__slideshow-jumpbutton");

  new SlideShow( slides
               , { dotNodes:  buttons
                 , pauseNode: node
                 });
}



function onScroll(evt)
{
/*
  if (!document.scrollingElement) // probably Internet Explorer or and old iOS (pre 9)
    return;

  // document.documentElement.scrollTop // Chrome
  // document.body.scrollTop // Safari
  let scrolly = document.scrollingElement.scrollTop; // iOS 9+, Edge 14+ and FF/Chrome

  // Is there room for the logo at the top left?
  if (scrolly > 15)
    document.documentElement.classList.add("hidedesktopheaderlogo");
  else
    document.documentElement.classList.remove("hidedesktopheaderlogo");


  if (scrolly > 45)
    document.documentElement.classList.add("hidemobileheaderlogo");
  else
    document.documentElement.classList.remove("hidemobileheaderlogo");


  if (scrolly > 400)
    document.documentElement.classList.add("compactsearchbar");
  else
    document.documentElement.classList.remove("compactsearchbar");

  if (scrolly > 270)
    document.documentElement.classList.add("compactsearchbar-filtersactive");
  else
    document.documentElement.classList.remove("compactsearchbar-filtersactive");
*/
}


window.addEventListener("scroll", evt => onScroll(evt));
