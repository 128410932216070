// import * as whintegration from "@mod-system/js/wh/integration";
import * as browser from 'dompack/extra/browser';
// import * as wrdauth     from "@mod-wrd/js/auth";

import * as dompack from "dompack";
import "@mod-newsletter/js/integration";
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';

// Behaviour and Polyfills
import Blazy from "./components/blazy"; // Lazy loading of images

import "./settings/rtd.css";
import "./settings/page-header.scss";
import "./settings/page-components.css";
import "./settings/page-multiviewresults.css";
import "./settings/page-tabs-arrows.css";
import "./settings/page-grid.scss";
import "./settings/results-list.css";



//import "./components/pagegrid/grid-mixins.scss";
// import "./settings/pagegrid.scss"; // SASS settings, such as pagegrid setup (columns/column size/gutter size)
// import "@mod-tue/webdesigns/shared/components/spc-pagegrid/grid-mixins.scss";
import "@mod-tue/webdesigns/shared/components/spc-multiviewresults";


import "@mod-tue/webdesigns/shared/components/spc-pagegrid/grid-layout.scss";
import "@mod-tue/webdesigns/shared/components/spc-pagegrid/grid-page-body.scss";
// import "@mod-tue/webdesigns/shared/components/spc-pagegrid/grid-page-gutter.scss";



import "./shared/rtd/rtd.es";
import "./shared/forms";
import "./shared/sidebar";

import "../shared/components/spc-tabstrip/pagetabs.scss";

import "./components/autosuggest";
import * as deeplinks from "./components/deeplinks";
import "./components/push-footer-to-bottom.scss";

import "./site.scss";
import "./header-site";
import "./header-page";
import "./footer";

import "./widgets/accordion";
import "./widgets/button";
import "./widgets/downloadsandlinks";
import "./widgets/cta";
import "./widgets/ctablocks";
import "./widgets/ctablocks-cbl";
import "./widgets/divider";
import "./widgets/image";
import "./widgets/news";
import "./widgets/persons";
import "./widgets/projects";
import "./widgets/richtext";
import "./widgets/video";
import "../shared/widgets/links";
import "../shared/widgets/highlight";

import "./pages/folderwithfilters";
import "./pages/news";
import "./pages/events";
import "./pages/projects";
import "./pages/project";
import "./pages/search";
//import "./pages/templatetest";
import "./pages/teachingtoolkit";
import "./pages/teachingtoolkit2";

import "./components/filteredoverview-styling/feedbackbar.scss";
import "./components/filteredoverview-styling/teaserbar.scss";

import "./site.lang.json";

import "@mod-publisher/js/analytics/ga4";

//var config = whintegration.config.site;


window.webhare = browser;


// open external links in new window
var ourdomain = (new URL(location.href)).host.toLowerCase();
openLinksInNewWindow(
    { extensions:    [ 'pdf' ]
    , internalhosts: [ ourdomain
                     , "www.tue.nl"
                     , "tue.nl"
                     ]
    });


dompack.onDomReady(function()
  {
    // lazy loading of images
    window.bLazy = new Blazy({
          offset: 100 // Loads images 100px before they're visible
      });

    // NOTE: On a project page we don't have space at the left for deeplinks
    // if (!document.documentElement.classList.contains("page--project"))
    if (document.documentElement.classList.contains("page-showdeeplinks"))
      deeplinks.activate();

    // When @mod-newsletter/js/integration is imported,
    // the form with class "whplugin-newsletter-subscription" will
    // be automatically handled.
    // (<newsletterintegration accounttag="..") should also be set in the siteprofile)
    window.addEventListener("wh:newsletter-subscribed", (evt) =>
    {
      //dompack.closest(evt.target, "footer").classList.add("footer--subscribed");
      document.documentElement.classList.add("subscribedtonewsletter");
    });


    if (document.documentElement.classList.contains("page--formwebtool"))
    {
      // Set class on the <form> so we can style with low specificy,
      // so we can try to prevent overriding the layout of widgets embedded in RTE fields.
      let webtoolform = document.querySelector("form.wh-form");
      webtoolform.classList.add("wh-form--webtoolform");
    }
  });
