import "./tt-category.scss";
import "./tt-overview.scss";
import "./contentsummary.css";
import * as dompack from "dompack";

import JSONRPC from "@mod-system/js/net/jsonrpc";



let rpc = new JSONRPC({ url: "/wh_services/tue/rpc/" });
let form;

dompack.onDomReady(initSearch);

function initSearch()
{
  if (!document.documentElement.classList.contains("page--tt-categoriesoverview"))
    return;

  form = document.querySelector(".pageheader-searchbar");
  if (form)
    form.addEventListener("submit", evt => doSearch(evt));
}

async function doSearch(evt)
{
  evt.preventDefault();

  let searchdata = {};
  if ("searchbardata" in form.dataset)
  {
    console.info("Additional searchdata", form.dataset.searchbardata);
    searchdata = JSON.parse(form.dataset.searchbardata);
  }

  let searchresults = await rpc.promiseRequest("SearchToolkitItems"
            , [ { query: evt.target.query.value
                // , restricturl: form.dataset.restricturl
                , ...searchdata
                }
              ]
            );

  document.documentElement.classList.add("page--searching");
  document.querySelector(".searchresults").innerHTML = searchresults.resultshtml;
}
